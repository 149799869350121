#skills > .content {

    ul {
        list-style: none;
        padding: 0;
        margin: 2rem 0 0 0;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }

    .skill {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        i {
            font-size: 4rem;
            transition: transform 0.5s;
            &:hover, &:focus {
                transform: scale(1.5);
            }
            margin-bottom: 0.75rem;
            color: $light-blue;
        }
    }

    @media screen and (max-width: $mobile-width) {
        ul {
            grid-template-columns: repeat(3, 1fr);
        }

        .skill {
            margin: 1rem 0.5rem;
        }

    }

    @media screen and (max-width: $small-mobile-width) {
        ul {
            grid-template-columns: repeat(2, 1fr);
        }

        .skill {
            margin: 0.5rem 0.5rem;
        }

    }
}