header {
    max-width: unset !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    background: $dark-blue;

    position: sticky;
    top: 0;

    padding: 0 1rem;
    box-sizing: border-box;
    z-index: 5;


    #name-wrapper {
        height: 100%;
    }

    img {
        margin: 0.5rem;
        height: calc(100% - 1rem);
        mix-blend-mode: luminosity;
    }

    button {
        display: none;
    }

    nav ul {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;

        li {
            margin: 0 0.25rem;
            font-size: 1rem;
        }

        li a {
            color: white;
        }

        li a {
            text-decoration: none;
            border: 1px solid transparent;
            border-radius: 5px;
            padding: 0.75rem 1rem;
            box-sizing: border-box;

            &:hover, &:focus {
                border-color: $white;
            }
        }

        a.btn {
            color: $light-blue;
            border-color: $light-blue;

            &:hover, &:focus {
                color: $white;
                border-color: $white;
            }
        }
    }

    @media screen and (max-width: $mobile-width) {
        transition: background 0.5s;

        button {
            display: block;
            background: no-repeat center/100% url('../../static/img/menu.svg');
            border: none;
            z-index: 15;
        }

        nav ul {
            flex-direction: column;
            align-items: center;
            padding-right: 10rem;
            position: fixed;
            background: $light-blue;
            top: -50rem;
            width: 100vw;
            // height: 17rem;
            padding-bottom: 1rem;
            transition: top 0.5s ease;
            left: 0;
            box-shadow: 0px 10px 10px black;

            a.btn {
                color: white;
                border-color: white;
            }
        }

        nav li {
            padding: 1rem 0;
            width: 100%;
            text-align: center;

            &:hover, &:focus-within {
                background: darken($light-blue, 4);
            }

        }


        &.expanded {

            background: $light-blue;
            nav ul {
                top: calc(5rem - 1px);
            }
        }
    }
}

header + .menu-overlay {
    content: ' ';
    position: fixed;
    pointer-events: none;
    height: 100vh;
    width: 100vw;
    background: transparent;
    top: 0;
    left: 0;
    transition: background 0.5s;
}

header.expanded + .menu-overlay {
    pointer-events: all;
    background: transparentize($black, 0.5);
}