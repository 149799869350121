#experience .content {
    font-size: 1.25rem;
    line-height: 2rem;

    .experiences-list {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 1rem;
    }

    button {
        width: 100%;
        text-align: left;
        border: none;
        color: white;

        background: transparentize($light-blue, 0.7);
        // background: #638475;
        border-bottom: 4px solid $border-green;
        border-radius: 5px;

        padding: 0.5rem 1rem;
        margin-top: 0.5rem;

        cursor: pointer;

        transition: border-radius 1s;
        &.btn-expanded {
            border-radius: 5px 5px 0 0;
        }

        & > h3 {
            display: inline-block;
        }

        & > div {
            margin: 0.5rem 0;
            font-size: 1rem;
            font-style: italic;
            color: $white;

            @media screen and (max-width: $small-mobile-width) {
                display: block;
            }
        }
    }

    .job-container {
        margin: 0;
        overflow: hidden;
        transition: max-height 1s, margin 1s;
        text-align: justify;

    }

    .job-details {
        display: flex;
        flex-direction: column;
        background: $border-green;
        padding: 1rem 2rem;
        border-radius: 0 0 5px 5px;
    }

    .job-description {
        padding: 0 2rem;
        ul {
            list-style: none;
            padding-left: 0;
            margin: 1rem 0;
        }

        li {
            position: relative;
            padding: 1rem 2rem;
            // margin-left: 1rem;
            &:before {
                position: absolute;
                content: '>';
                right: calc(100% - 1.5rem);
                color: red;
                font-weight: bold;

            }
        }

        @media screen and (max-width: $small-mobile-width) {
            li {
                padding: 1rem 0;
                text-align: left;

                &:before {
                    right: calc(100% + 1rem);
                }
            }
        }
    }
}