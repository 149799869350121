#projects .content {
    & > ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .project {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 3rem 0 2rem 0;
    }

    .project-img {
        $img-width: 100%;
        width: $img-width;
        height: calc((#{$img-width} / 4) * 3);
        position: relative;

        border-top: 1.5rem solid $terminal-bar;
        border-radius: 5px 5px 0 0;

        img {
            width: 100%;

        }

        &:after, &:before {
            position: absolute;
            content: ' ';
            height: 1rem;
            width: 1rem;
            background: $window-red;
            border-radius: 100%;
            left: 0.5rem;
            top: -1.25rem;
        }

        &:after {
            left: 2rem;
            background: $window-green;
        }
    }

    .content-container {
        padding-left: 1rem;
        align-self: center;

        h3 {
            background: $terminal-bar;
            color: $black;
            font-family: 'Source Code Pro', monospace;
            font-weight: normal;
            font-size: 1.125rem;
            padding: 0 4rem;
            border-radius: 5px 5px 0 0;
            position: relative;

            &:after, &:before {
                position: absolute;
                content: ' ';
                height: 1rem;
                width: 1rem;
                background: $window-red;
                border-radius: 100%;
                left: 0.5rem;
                top: calc((100% - 1rem)/2);
            }

            &:after {
                left: 2rem;
                background: $window-green;
            }

        }

        .terminal-content {
            font-size: 1rem;
            padding: 0.5rem;
            color: $terminal-green;
            background: $black;
            border-radius: 0 0 5px 5px;
            a {
                color: $terminal-red;
            }
        }

        .terminal-content ul {
            list-style: none;
            display: flex;
            justify-content: flex-end;
            padding: 0;
            margin: 1rem 0;

            li {
                margin: 0 1rem 0 0;
                text-decoration: underline;
                color: $light-blue;
                font-size: 1rem;
            }
        }

        .project-links {
            display: flex;
            justify-content: flex-end;
            font-size: 1.5rem;
            a {
                color: $white;
                padding: 0 0.5rem;
                border-radius: 5px;
            }

            a:hover, a:focus {
                color: $black;
                background: $white;
            }
        }

        p {
            margin: 0;
        }
    }

    @media screen and (max-width: $tablet-width) {
        .project {
            grid-template-columns: 1fr;
        }

        .project-img {
            margin-top: 2rem;
            grid-row-start: 2;
            justify-self: center;
            width: 75%;
        }

        .content-container {
            padding: 0;
            align-self: flex-start;
        }
    }

    @media screen and (max-width: $mobile-width) {
        .project-img {
            width: 100%;
        }
    }

}