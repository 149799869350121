$small-mobile-width: 640px;
$mobile-width: 850px;
$tablet-width: 1023px;
$twok-width: 1920px;
$fourk-width: 2560px;

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import './colors.scss';

@mixin accessible-text {
    position: absolute;
    left: -10000000px;
}

@mixin unset-accessible-text {
    position: unset;
    left: unset;
}

.accessible-text {
    @include accessible-text;
}

html {
    margin: 0;
    padding: 0;
    background: $dark-blue;
    color: $white;
    font-size: 1em;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Source Code Pro', monospace;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
}

h1, h2, h3, h4 {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

h2 {
    font-size: 2.25rem;
}

#root {

    position: relative;

    & > * {
        width: 100%;
        margin: 0 auto;
    }

}

.skipnav {
    position: fixed;
    top: -10rem;
    left: -10rem;
    text-decoration: none;

    &:focus {
        top: 0;
        left: 0;
        color: black;
        background: rgba(255,255,255,0.8);
        z-index: 10;
        width: 10rem !important;
        padding: 1rem
    }
}

main section:not(#splash) {
    min-height: 100vh;
    display: flex;
    align-items: center;

    & > .content {
        padding: 6rem 5rem 5rem 5rem ;
        margin: 0 auto;
        max-width: $tablet-width;
        font-size: 1.25rem;
        line-height: 2rem;
        height: calc(100% - 10rem);
    }
}

main section:nth-child(even) {
    background: transparentize($light-blue, 0.8);
    // color: $ivory;
}

@media screen and (max-width: $small-mobile-width) {
    html {
        font-size: 0.75em;
    }
}

@media screen and (orientation: landscape) and (max-height: $small-mobile-width) {
    html {
        font-size: 0.75em;
    }
}

@media screen and (min-width: $twok-width) {
    // html {
    //     font-size: 1em;
    // }

    // main section:not(#splash) {
    //     & > .content {
    //         max-width: 1366px;
    //     }
    // }
}

@media screen and (min-width: $fourk-width) {
    html {
        font-size: 1.5em;
    }
    main section:not(#splash) {
        & > .content {
            max-width: $twok-width;
        }
    }
}

@import '../components/splash/splash.scss';
@import '../components/header/header.scss';
@import '../components/about/about.scss';
@import '../components/skills/skills.scss';
@import '../components/projects/projects.scss';
@import '../components/experience/experience.scss';
@import '../components/contact/contact.scss';