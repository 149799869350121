#splash {
    height: calc(100vh - 5rem);
    padding-bottom: 5rem;
    box-sizing: border-box;
    max-width: $tablet-width;

    @keyframes flashing {
        0% { opacity: 0; }
        1% { opacity: 1; }
        50% { opacity: 1; }
        51% { opacity: 0; }
        100% { opacity: 0; }
    }

    .flash {
        animation: flashing 1s infinite;
    }

    .cursor {
        display: inline-block;
        transform: scaleX(0.5);
    }

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 50%;
    margin: 0 auto;

    .splash-main-text {
        display: flex;
        flex-direction: column;
        font-family: 'Source Code Pro', monospace;

        font-size: 2rem;
        font-weight: normal;

        .name {
            font-size: 5rem;
            font-weight: bold;
            margin: 0.75rem 0;
        }
    }

    .code-line {
        font-size: 1.25rem;
        position: relative;
        margin-bottom: 1rem;
        line-height: 2rem;
        &.active:before {
            position: absolute;
            right: calc(100% + 1ch);
            content: '> ';
            color: red;
        }
    }

    @media screen and (max-width: $tablet-width) {
        width: 75%;
    }

    @media screen and (min-width: $twok-width) {
        max-width: 1366px;
    }

    @media screen and (min-width: $fourk-width) {
        max-width: $twok-width;
    }

}