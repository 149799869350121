// Generated on coolors.com

/* SCSS HEX */
$dark-blue:        #001D3D;
$light-blue:       #47A3FF;
$border-green:     #638475;
$ivory:            #FBFFF1;

$white: #ffffff;
$black: #191919;
$terminal-bar: rgb(227, 227, 227);
$terminal-green: #13c24f;
$terminal-red: red;

$window-green: #009e36;
$window-red: #d30909;

