#about .content {
    display: grid;
    grid-template-columns: 60% auto;
    grid-template-rows: 2.5rem auto;
    text-align: justify;

    h2 {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    & > div {
        align-self: center;
    }

    .img-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    @media screen and (max-width: $mobile-width) {
        grid-template-columns: 1fr;
        padding: 5rem;

        h2 {
            grid-column-start: 1;
            grid-column-end: 2;
        }
    }

}