#contact .content {
    h2 {
        text-align: center;
        margin-bottom: 4rem;
    }
    ul {
        list-style: none;
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 0;
        padding: 0;
        margin-top: 1rem;
    }

    li a {
        position: relative;
        color: $white;
        font-size: 2rem;
        margin: 0 1rem;
    }

    li a span {
        @include accessible-text;
        background: transparentize($black, 0.2);
        font-size: 1rem;
        padding: 0.5rem;
    }

    li:hover, a:focus {
        span {
            bottom: calc(100% + 0.5rem);
            left: -50%;
        }
    }

    .contact-form {
        margin-top: 2rem;
        text-align: center;
    }

    .resume-link {
        margin-top: 2rem;
        display: flex;
        justify-content: center;

        a {
            color: $white;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $white;
            padding: 0.5rem 1rem;
            border-radius: 5px;
        }

        i {
            margin-right: 1rem;
            font-size: 1.5rem;
        }

        a:hover, a:focus {
            background: $white;
            border-color: transparent;
            color: $dark-blue;
        }
    }
}